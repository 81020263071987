* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body {
  background: rgb(39, 39, 39);
}

.hide {
  overflow: hidden;
}

/* Header starts here */
.nav {
  min-height: 10vh;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 8rem;
  width: 100%;
  z-index: 2;
  position: fixed;
  background-color: transparent;
  transition: all 0.5s ease-in-out;
}

.nav.active {
  background-color: #3a3a3a;
  opacity: 0.9;
  transition: all 0.5s ease-in-out;
}

.nav p {
  font-size: 4rem;
  transition: all 0.5s ease;
}

.nav p:hover {
  transform: scale(1.04);
}

.line {
  height: 0.1rem;
  background-image: linear-gradient(to right, #42e3ff, rgb(0, 189, 110));
  width: 0%;
  position: absolute;
  bottom: -20%;
  left: 50%;
}

a {
  color: white;
  text-decoration: none;
}

ul {
  display: flex;
  list-style: none;
}

li {
  padding-left: 5rem;
  position: relative;
  cursor: pointer;
  font-size: 1.2rem;
}

.scroll-btn {
  opacity: 0;
  margin: 1%;
  position: fixed;
  height: 60px;
  width: 5%;
  right: -20%;
  bottom: 0;
  cursor: pointer;
  z-index: 1;
  color: white;
  transition: all 0.5s ease;
}

.scroll-btn:hover {
  transform: scale(1.05);
}

.scroll-btn.active {
  opacity: 1;
  right: 0;
}

.mobile-icon {
  display: none;
}

.menu-container {
  display: none;
}

.menu {
  display: none;
}

@media only screen and (min-width: 320px) and (max-width: 580px) {
  .nav{
    height: 12vh;
  }
  .nav p {
    text-align: center;
    position: absolute;
    font-size: 4rem;
    left: 37%;
    top: 5%;
  }
  .nav p:active {
    transform: scale(1);
  }
  ul li {
    display: none;
  }
  .mobile-break {
    display: block;
  }
  .scroll-btn {
    width: 14%;
  }
  .scroll-btn:hover {
    transform: scale(1);
  }
  .menu-container {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    transform: translateX(-100%);
    overflow: hidden;
    top: 0;
    z-index: 2;
    background-image: linear-gradient(to right, black, transparent);
    opacity: 0;
    transition: all 0.5s ease;
  }
  .menu-container-active {
    opacity: 1;
    transform: translateX(0);
  }
  .mobile-icon {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    margin: 1.3rem 0rem 0rem 1rem;
    z-index: 3;
  }
  .menu {
    display: block;
    position: absolute;
    height: 50vh;
    width: 100%;
    margin-top: 30%;
    z-index: 2;
  }
  .menu div {
    font-size: 1.7rem;
    margin: 3rem 0rem 0rem 4rem;
  }
  .menu-icons {
    position: absolute;
    left: 0;
    margin-top: 0.1rem;
    margin-left: 0.5rem;
  }
}

/* Banner image starts here */
.banner-image {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  opacity: 0.5;
  z-index: -1;
}

.title {
  width: 60%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -60%);
  color: white;
}

.title h2 {
  font-size: 4.5rem;
  padding: 0%;
  
}

.button1 {
  font-size: 1.1rem;
  cursor: pointer;
  padding: 1rem 1rem;
  outline: none;
  border: 2px solid #42e3ff;
  background-color: transparent;
  color: white;
  transition: all 0.5s ease;
}

.button1:hover {
  background-color: #42e3ff;
  color: black;
}

.button2 {
  font-size: 1.1rem;
  cursor: pointer;
  padding: 1rem 1rem;
  outline: none;
  border: 2px solid #42e3ff;
  background-color: transparent;
  color: white;
  margin: 1rem;
  transition: all 0.5s ease;
}

.button2:hover {
  background-color: #42e3ff;
  color: black;
}

.button3 {
  font-size: 1.1rem;
  cursor: pointer;
  padding: 1rem 1rem;
  outline: none;
  border: 2px solid #42e3ff;
  background-color: transparent;
  color: white;
  margin: 3rem;
  transition: all 0.5s ease;
}


.button3:hover {
  background-color: #42e3ff;
  color: black;
}

.socials {
  display: block;
}

.socials a:hover {
  color: #42e3ff;
}

.socials a {
  padding: 0rem 0.5rem 0rem 0rem;
  color: white;
  transition: all 0.5s ease;
}

.scroll-arrow {
  position: absolute;
  bottom: 0;
  left: 49%;
  cursor: pointer;
}

.contact-message {
  color: white;
  text-align: center;
}

@media only screen and (min-width: 320px) and (max-width: 580px) {
  .details-container {
    margin: auto;
  }
  .button1 {
    position: absolute;
    top: 76%;
    left: 33%;
  }
  .title {
    width: 90%;
  }
  .title h2 {
    font-size: 3rem;
  }
  .title h1{
    margin-bottom: 4%;
  }
  .scroll-arrow {
    display: none;
  }
}

/* Iphone 11 media queries */

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {

}

/* About Section */
.container {
  height: 97vh;
}

.title-container {
    padding: 5rem 8rem 1rem 8rem;
  }
  
.title-container h2 {
    font-size: 3rem;
    font-weight: lighter;
    color: white;
    text-align: center;
  }

.info-container {
  padding: 3rem 10rem 0rem 0rem;
  width: 90%;
  margin: 0 auto;
}

.info-container h2 {
  font-size: 2.3rem;
  color: white;
  font-weight: lighter;
}

.content-message {
  padding: 1.2rem 3rem 0rem 0rem;
}

.content-message p {
  font-size: 1.2rem;
  color: white;
}

@media only screen and (min-width: 320px) and (max-width: 580px) {
  .info-container {
    position: absolute;
    padding: 0%;
    text-align: center;
    left: 5%;
  }
  .info-container h2 {
    font-size: 1.8rem;
    text-align: center;
  }
  .content-message {
    font-size: 1rem;
    padding: 1rem 0rem 0rem 0rem;
  
  }
}

/* Design Section */
.design-container {
  margin-top: 5%;
  min-height: 30vh;
  display: flex;
  width: 100%;
  padding: 0rem 3rem 0rem 3rem;
}

.design-icons {
  color: white;
  padding: 5rem 0rem 5rem 0rem;
  width: 100%;
  display: grid;
  place-items: center;
}

.design-icons h2 {
  color: white;
  font-size: 2.8rem;
  font-weight: lighter;
  color: #42e3ff;
  padding: 0rem;
}

@media only screen and (min-width: 320px) and (max-width: 580px) {
  .design-container {
    margin: 6rem 0rem 6rem 0rem;
    display: grid;
    grid-template-columns: repeat(2, 200px);
    padding: 0rem;
  }
  .design-icons h2 {
    font-size: 2rem;
  }
}

/* About Questions */
.question {
  text-align: center;
  color: white;
  padding: 0rem 10rem 0rem 10rem;
  width: 95%;
  margin: 0 auto;
}

.answer {
  padding: 0.5rem 0rem;
  color: white;
}

.answer p {
  padding: 0rem 3rem 0rem 3rem;
  font-size: 1rem;
}

.question-h2 {
  padding: 1rem;
  font-size: 1.8rem;
  font-weight: lighter;
  cursor: pointer;
}

.question-line {
  background-image: linear-gradient(to right, #42e3ff, rgb(140, 0, 255));
  height: 0.25rem;
  margin: 0rem 0rem 1rem 0rem;
  width: 100%;
  text-align: center;
}

.question-icon {
  transform: rotate(-180deg);
  margin: 1rem 0rem 0rem 1rem;
  transition: all 0.5s ease;
}

.question-icon.active {
  transform: rotate(0);
  transition: all 0.5s ease;
}

@media only screen and (min-width: 320px) and (max-width: 580px) {
  .question {
    margin-top: 2rem;
    padding: 0rem 1.5rem 0rem 1.5rem;
  }
  .question-h2 {
    font-size: 1.2rem;
    padding: 0.5rem;
  }
  .answer p {
    padding: 0rem;
    font-size: 1rem;
  }
  .about-me {
    min-height: 85vh;
    top: 20%;
    width: 100%;
  }
}

/* Projects Section */
.svg-image {
  position: absolute;
  margin-top: 10%;
  z-index: -1;
  left: 0;
}

.back-btn {
  color: white;
  position: absolute;
  margin-top: 1rem;
  cursor: pointer;
}

#projects {
  min-height: 97vh;
}

.image-container {
  display: grid;
  padding: 0rem 5rem 0rem 5rem;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-row-gap: 2rem;
}

.image-array {
  height: 45vh;
  width: 100%;
  cursor: pointer;
  object-fit: cover;
  display: block;
  width: auto;
  transition: transform 1s, filter 0.5s ease-in-out;
  filter: brightness(65%);
}

.image-array:hover {
  filter: brightness(100%);
  transform: scale(1.01);
}

.project h2 {
  padding: 0.8rem 0rem 0rem 0.5rem;
  font-size: 1.25rem;
  text-align: left;
  font-weight: lighter;
  color: white;
}

.project p {
  padding: 0.1rem 0rem 0rem 0.5rem;
  color: rgb(192, 192, 192);
}

.list-container{
  margin-bottom: 1%;
}

@media only screen and (min-width: 320px) and (max-width: 580px) {
  .title-container {
    padding: 6rem 3rem 1rem 3rem;
  }
  .title-container h2 {
    font-size: 2.2rem;
    padding: 0rem;
  }
  .question-line {
    margin: 0.2rem 0rem 1rem 0rem;
  }
  .image-array {
    height: 30vh;
    width: 100%;
    filter: brightness(100%);
    transform: scale(1);
  }
  .image-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    padding: 0rem;
  }
  .svg-image {
    display: none;
  }
  .back-btn {
    margin-top: 0.3rem;
  }
  .project p {
    padding: 0rem 0rem 0rem 0.55rem;
  }
}

/* Work Details */
.details {
  padding: 0rem 4rem 0rem 4rem;
  display: flex;
}

.details p {
  color: white;
  word-wrap: break-word;
  padding: 2rem 2rem 0rem 2rem;
}

.details-container {
  width: 100%;
}

.btncontainer1{
  margin-top: 10%;
  text-align: center;

}

.detail-image {
  height: 70vh;
  width: 45%;
  object-fit: cover;
}

.learnt {
  min-height: 35vh;
  display: flex;
  margin: 0rem 2rem;
  align-items: center;
  justify-content: space-around;
}

.learnt-style {
  padding: 0rem 2rem 0rem 2rem;
  text-align: center;
}

.learnt-style h3 {
  font-size: 2rem;
  color: white;
  padding: 1rem;
  font-weight: lighter;
}

.learnt-style p {
  color: white;
}

.gallery {
  padding: 2.5rem;
  color: white;
  text-align: center;
  font-weight: lighter;
  font-size: 2.5rem;
}



.btncontainer {
  display: block;
  margin-top: 3rem;
  margin-left: 1rem;
}

@media only screen and (min-width: 320px) and (max-width: 580px) {
  .details {
    display: block;
    padding: 0rem;
    text-align: center;
  }
  .detail-image {
    height: 30vh;
    width: 100%;
  }
  .learnt {
    display: block;
    padding: 5rem 0rem 5rem 0rem;
  }
  .learnt-style h3 {
    font-size: 1.9rem;
  }
  .learnt-style p {
    font-size: 1.05rem;
  }
  .gallery {
    font-size: 2rem;
  }
  .btncontainer {
    text-align: center;
  }
}

/* Carousel */
.carousel-container {
  display: flex;
  width: 60%;
  height: 65vh;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  margin-bottom: 3%;
}

.carousel-slides {
  min-width: 100%;
  height: auto;
  transition: all 1s ease;
  position: relative;
}

.img-container {
  width: 100%;
  display: flex;
  height: auto;
  justify-content: center;
}

.prev {
  position: absolute;
  left: 0;
  height: 100%;
  width: 8%;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  transition: all 0.5s ease;
}

.prev:hover {
  background: rgba(255, 255, 255, 0.4);
}

.next {
  position: absolute;
  right: 0;
  height: 100%;
  width: 8%;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  transition: all 0.5s ease;
}

.next:hover {
  background: rgba(255, 255, 255, 0.4);
}

@media only screen and (min-width: 320px) and (max-width: 580px) {
  .carousel-container {
    width: 100%;
    height: 30vh;
  }
}

/* Contact Section */
.form-container {
  height: 100%;
  width: 40%;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background: transparent;
  opacity: 0.9;
  text-align: center;
}

.btn-container {
  text-align: center;
  margin-top: 2%;
}

form input {
  min-height: 1.5rem;
  width: 100%;
  background: white;
  outline: none;
  border: none;
  height: 2rem;
  color: black;
  margin-bottom: 1rem;
  margin-top: 0.3rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  padding-left: 10px;
}

form textarea {
  min-height: 20vh;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 0.3rem;
  margin-top: 0.5rem;
  background: white;
  color: black;
  font-size: 1rem;
  resize: none;
}

form label {
  color: white;
  font-size: 1.1rem;
}

form button {
  position: sticky;
  left: 44%;
}

@media only screen and (min-width: 320px) and (max-width: 580px) {
  .form-container {
    height: 55vh;
    width: 100%;
    display: block;
  }
  .btn-container {
    display: block;
    margin: 0rem;
  }
  #contact-btn {
    position: absolute;
    left: 15%;
    width: 50%;
  } 
  form input {
    height: 2rem;
  }
  form textarea {
    min-height: 15vh;
  }
}

/* Footer Section */
footer {
  width: 100%;
  text-align: center;
}

footer h3 {
  color: white;
  font-weight: lighter;
}

@media only screen and (min-width: 320px) and (max-width: 580px) {
  footer h3 {
    text-align: center;
    font-size: 0.9rem;
  }
}

/* Error Message */
.error {
  transform: translate(0%, 100%);
  text-align: center;
}

.error h1 {
  font-weight: lighter;
  font-size: 5rem;
  color: red;
}

/* Large Screen media queries */
@media only screen and (min-device-width: 1800px) {
  .info-container h2 {
    font-size: 2.5rem;
  }
  .info-container h3 {
    font-size: 1.8rem;
  }
  .info-container p {
    font-size: 1.4rem;
  }
  .question-h2 {
    font-size: 2.2rem;
  }
  .answer p {
    font-size: 1.3rem;
  }
  .details {
    padding: 0rem 8rem 0rem 8rem;
  }
  .details p {
    font-size: 1.6rem;
  }
  #link-btn {
    right: 44%;
  }
  .learnt-style h3 {
    font-size: 2.5rem;
  }
  .learnt-style p {
    font-size: 1.2rem;
  }
  form input {
    width: 100%;
    height: 2.1rem;
  }
  form textarea {
    min-height: 25vh;
  }
  form label {
    font-size: 1.2rem;
  }
  .image-container {
    padding: 0rem 0rem 0rem 10rem;
    grid-template-columns: repeat(auto-fit, minmax(800px, 1fr));
    grid-row-gap: 4rem;
  }
  }
  .about-me{
    height: 97vh;
    padding-top: 13%;
  }

.alert {
  position: absolute;
  left: 45%;
  top: 90%;
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  color: yellowgreen;
}



@media only screen and (min-width: 320px) and (max-width: 580px) {
  .alert {
    top: 95%;
    left: 35%;
  }
}
